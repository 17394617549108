import React from "react"
import Image from "gatsby-image"
import styles from "../../css/gite.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const getImage = graphql`
  query {
    file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Gite = ({ gite }) => {
  const data = useStaticQuery(getImage)
  const img = data.file.childImageSharp.fluid
  const { titre, slug } = gite
  const topImage = gite.topImage.fluid || img
  const title = gite.topImage.title || "gîte"
  return (
    <article className={styles.gite}>
      <div className={styles.imgContainer}>
        <Image fluid={topImage} className={styles.img} alt={title} />
        <AniLink fade className={styles.link} to={`/gites/${slug}`}>
          sélectionner
        </AniLink>
        <div className={styles.footer}>
          <h3>{titre}</h3>
        </div>
      </div>
    </article>
  )
}

Gite.propTypes = {
  gite: PropTypes.shape({
    titre: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    topImage: PropTypes.object.isRequired,
  }),
}

export default Gite
